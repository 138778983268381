<template>
    <vs-row style="height:calc(90vh - 100px); width: 100%;">
        <flipbook class="flipbook" :pages="pagesPreviews" v-slot="flipbook" ref="flipbook" :ambient="1" :clickToZoom="false" @flip-right-end="pageFlipped" @flip-left-end="pageFlipped" :dragToFlip="false" >

            <vs-row justify="center" align="center" style="gap: 50px; margin-bottom: 50px;">
                
                <vs-button @click="$router.go(-1)" v-if="fromBuilder">
                    <i class='bx bx-layout bx-sm'></i> Torna al Builder
                </vs-button>
                <vs-button @click="$router.go(-1)" v-else>
                    <i class='bx bx-cart-alt bx-sm'></i> I miei ordini
                </vs-button>
                <vs-button circle icon :disabled="!flipbook.canFlipLeft" @click="flipbook.flipLeft"><i class='bx bx-chevron-left bx-sm'></i></vs-button>
                <span class="page-num">
                    Pagina {{ flipbook.page }} di {{ flipbook.numPages }}
                </span>
                <vs-button circle icon :disabled="!flipbook.canFlipRight" @click="flipbook.flipRight"><i class='bx bx-chevron-right bx-sm'></i></vs-button>
                <vs-button @click="downloadOrderFile" icon  :disabled="fromBuilder">
                    <i class='bx bx-download bx-sm'></i> Download
                </vs-button>

            </vs-row>

        </flipbook>
    </vs-row>
    </template>
<script>
import { s3Client } from '../s3Client.js';
import {
    apiCall,
    baseUrl,
    
} from '../client';
import Flipbook from '../components/flipbook.es.js'

    export default ({

        components: {
            Flipbook
        },

        data() {
            return {
                pagesPreviews: [],
                zipFileId: null
            }
        },

        computed: {
            fromBuilder(){
                // check if previous route was the builder
                return this.$route.params.fromBuilder;
            }
        },

        async mounted() {
            console.log(this.$route.params)
            var result = await apiCall('GET', '/Order/' + this.$route.params.orderId + '/Preview');
            if (result.status == 200) {
                this.zipFileId = result.data.zipFileId;
                let pagesPreviews = await Promise.all(result.data.pages.map(async (page) => {
                    console.log(page);
                    //if(page.pngfileId == null){
                        // try to get it from page id (might be in preview mode, the product is not rendered yet)
                        const signedUrl = await s3Client.getSignedDownloadURL({name: page.id});
                        const exists = await s3Client.fileExists({url: signedUrl.url});
                        if(exists){
                            return signedUrl.url;
                        }

                        const layoutSignedUrl = await s3Client.getSignedDownloadURL({name: page.layout.previewPictureId});
                        return layoutSignedUrl.url;
                    //}
                    //const signedUrl = await s3Client.getSignedDownloadURL({name: page.pngfileId}); // this is very huge and slow
                    //return signedUrl.url;
                }));

                this.pagesPreviews = [null].concat(pagesPreviews);
            }  
        },

        methods: {
            pageFlipped(){

            },

            async downloadOrderFile(o) {
                const downloadUrl = await s3Client.getSignedDownloadURL({
                    name: this.zipFileId + '.zip'
                });

                if (downloadUrl && downloadUrl.url) {
                    // force download
                    var link = document.createElement('a');
                    link.href = downloadUrl.url;
                    link.setAttribute('download', o.zipFileId + '.zip');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },

        }
    
    })
    </script>
    <style>
.flipbook {
    width: 100%;
    height: calc(80vh - 100px);
}

.flipbook-container {
    margin-bottom: 100px;
}

.flipbook .bounding-box {
    box-shadow: 0 0 20px rgb(145, 145, 145);
}
</style>